@tailwind base;
@tailwind components;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  @font-face {
    font-family: "TypoDraft";
    src: url("fonts/TypoDraft.woff2") format("woff2"),
      url("fonts/TypoDraft.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

@tailwind utilities;
