.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blueprint-line {
  border-top: 2px solid black;
  background: black;
  width: 100%;
  transform: translateY(-50%);
}

li.step:after {
  content: "";
  z-index: 1;
  position: relative;
  grid-column-start: 1;
  grid-row-start: 1;
  display: grid;
  height: 2rem;
  width: 2rem;
  place-items: center;
  place-self: center;
  border-radius: 9999px;
  outline: 1px solid white;
  background: rgb(64, 71, 94);
}

li.step:before {
  content: "";
  height: 100%;
  top: 0;
  grid-column-start: 1;
  grid-row-start: 1;
  border-left: 2px dashed white;
  transform: translateY(-50%);
  margin-left: 48%;
}

li.step:first-of-type:before {
  display: none;
}
